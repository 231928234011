<template>
  <div
    class="modal fade px-0"
    id="batchApplyTemplate"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-md mx-0 mx-md-auto"
      role="document"
    >
      <div
        class="modal-content batch-template-modal animate-bottom br-18 b-gold"
        style="
          border: 0.5px solid #0b5786 !important;
          box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
        "
      >
        <div
          class="modal-header p-0 d-flex align-items-center"
          style="border-bottom: 0.5px solid #707070 !important; opacity: 0.598"
        >
          <span class="col-2"></span>
          <h5
            class="font-md-18 font-16 px-0 pb-12p pb-md-20 pt-md-22 pt-14p text-white col-8 text-center kallisto-bold"
          >
            Batch apply templates
          </h5>
          <button
            type="button"
            class="close m-0 col-2 pr-20 d-flex justify-content-end"
            data-dismiss="modal"
            @click="emptyModalData()"
          >
            <img
              :src="getIcon('x-button.svg')"
              alt="x-button"
              class="x-button"
            />
          </button>
        </div>
        <div v-if="currentStep === 1">
          <div class="modal-body px-6 text-left os-regular pt-24">
            <div class="pt-4 d-flex border-bottom-white my-auto">
              <p
                class="font-18 font-md-16 font-weight-bold m-0 px-0 align-items-center mr-2"
                style="color: #d0d0d0"
              >
                Available seats
              </p>
              <p
                class="font-16 font-md-14 font-weight-bold border rounded px-4"
                style="border-color: #d0d0d0"
              >
                {{ $store.state.calendarSelectedGearup.max_users }}
              </p>
            </div>
            <div class="border-bottom-white mt-0">
              <div class="col-md-12 d-flex align-items-center px-0 py-4">
                <label class="font-18 font-md-16 font-weight-bold pb-0 mb-0">
                  Pod
                </label>
              </div>
              <div class="col-md-12 pb-5 px-0 pt-0">
                <div v-if="$store.state.scheduler_pods">
                  <select
                    name="LeaveType"
                    @change="onChangePod($event)"
                    v-model="this.$store.state.calendarSelectedPod"
                    class="form-control choose-an-act text-left font-weight-bold font-16 font-md-14"
                  >
                    <option value="" selected disabled>Select pod</option>
                    <option
                      v-for="option in converToArray(
                        this.$store.state.scheduler_pods
                      )"
                      :value="option.key"
                      :key="option.key"
                      class="text-white"
                    >
                      Pod {{ option.key }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="justify-content-center border-bottom-white">
              <p class="mb-0 py-4 font-weight-bold font-18 font-md-16">Show</p>
              <select
                name="LeaveType"
                @change="onChangeAct($event)"
                v-model="this.selectedTitleId"
                placeholder="Select a show"
                class="font-16 font-md-14 px-3 choose-an-act text-white text-left font-weight-bold form-control mb-5"
              >
                <option class="text-left" disabled selected value>
                  Select a show
                </option>
                <option
                  class="text-left"
                  v-for="(act, i) in this.$store.state.titles"
                  :key="i"
                >
                  {{ act.shortname }}
                </option>
              </select>
            </div>
            <!-- <div class="border-bottom-white mt-0 mx-5">
              <div class="col-md-12 d-flex align-items-center px-0 py-4">
                <label class="font-18 font-md-16 font-weight-bold pb-0 mb-0">
                  Reservation type
                </label>
              </div>
              <div class="col-md-12 pb-5 px-0 pt-0">
                <select
                  name="LeaveType"
                  v-model="reservationType"
                  placeholder="Select reservation type"
                  class="form-control choose-an-act text-left font-weight-bold font-16 font-md-14"
                >
                  <option value="" selected disabled>
                    Select reservation type
                  </option>
                  <option class="ml-3 text-left" value="group">
                    Group reservation
                  </option>
                  <option value="individual">Individual reservation</option>
                </select>
              </div>
            </div> -->
            <div class="">
              <div class="pt-4 pb-10p">
                <h3 class="font-18 font-md-16 font-weight-bold d-flex m-0 px-0">
                  Date
                </h3>
              </div>
              <div>
                <div class="justify-between d-flex">
                  <div class="w-100">
                    <datepicker
                      v-model="batchTemplateStartDate"
                      :format="customFormatter"
                      :highlighted="getCurrentDate()"
                      class="cursor-pointer date-picker-style-max-length py-3 ml-0 font-16 font-md-14"
                      :disabled-dates="disabledDates"
                      placeholder="Select date"
                    >
                    </datepicker>
                  </div>
                  <p
                    class="font-16 font-md-14 font-weight-bold d-inline-block m-0 px-10"
                    style="align-self: center"
                  >
                    to
                  </p>
                  <div class="w-100">
                    <datepicker
                      v-model="batchTemplateEndDate"
                      :format="customFormatter"
                      :highlighted="getCurrentDate()"
                      class="cursor-pointer date-picker-style-max-length py-3 ml-0 font-16 font-md-14"
                      :disabled-dates="disabledDates"
                      placeholder="Select date"
                    >
                    </datepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="justify-center d-flex">
              <button
                type="button"
                class="btn btn-warning button-next py-1 px-5 os-bold"
                @click="gotoNext"
                :disabled="!isFormComplete"
                style="width: 179px !important; height: 42px !important"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div v-if="currentStep === 2">
          <div class="pt-24 font-weight-bold px-6">
            <p
              class="font-18 font-md-16 mb-0 font-weight-bold d-flex"
              style="color: #d0d0d0"
            >
              Select templates
            </p>
          </div>
          <div class="template-v-for border-0 mt-5">
            <div
              v-for="(template, i) in this.$store.state
                .batchTemplateMultiSelect"
              :key="i"
              class="batch-list-div pt-md-3 px-md-10"
              style="background: #181818"
            >
              <div class="row batch-list os-bold border-0">
                <div class="pt-20">
                  <div class="cursor-pointer">
                    <label class="checkbox-container mb-0">
                      <input
                        type="checkbox"
                        :class="template.checked ? 'highlighted_url' : ''"
                        @click="chosenTemplate(template)"
                      />
                      <span class="checkmark rounded-sm"></span>
                      <div>
                        <h2 class="font-14 font-md-16 m-0 os-semibold pl-3">
                          {{ template.schedule_name }}
                        </h2>
                      </div>
                    </label>

                    <span
                      class="col-9 col-lg-12 font-12 font-md-14 px-0 os-semibold"
                    >
                      <p
                        class="d-flex flex-row font-12 font-md-14 text-left ml-9 text-muted"
                        :style="{
                          color: template.checked ? '#ffffff' : '#929292',
                        }"
                      >
                        {{ convertTime(template.local_times) }}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer d-flex justify-content-center mt-34 mb-32 p-0"
          >
            <button
              type="button"
              class="go-back-button-batch-apply"
              @click="goBack()"
            >
              Go back
            </button>
            <button
              type="button"
              :class="
                this.applyButtonDisabled
                  ? 'apply-button'
                  : 'apply-button-disabled'
              "
              @click="executeBatchApply()"
              :disabled="!this.applyButtonDisabled"
            >
              <span class="font-weight-bold">Apply</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import $ from "jquery";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      //Batch Apply Template Modal Data *********************
      applyButtonDisabled: false,
      chosenBatchTemplate: "",
      batchTemplateStartDate: null,
      batchTemplateEndDate: null,
      selectedTitleId: "",
      reservationType: "individual",
      currentStep: 1,
      //   batchTemplateMultiSelect: [],
      templates: this.$store.state.templates,
      seatCapacity: "",
      disabledDates: {
        to: new Date(Date.now() - 8640000),
        // days: [6, 0], // Disable Saturday's and Sunday's
      },
      //*********************
    };
  },
  async mounted() {
    await this.$store.dispatch("setListOfTemplates").then(() => {});
  },
  watch: {
    applyButtonDisabled() {
      this.applyButtonToggle();
    },
    batchTemplateStartDate() {
      this.applyButtonToggle();
    },
    batchTemplateEndDate() {
      this.applyButtonToggle();
    },
    selectedTitleId() {
      this.applyButtonToggle();
    },
  },
  computed: {
    isFormComplete() {
      return (
        this.batchTemplateStartDate &&
        this.batchTemplateEndDate &&
        this.reservationType &&
        this.$store.state.calendarSelectedPod &&
        this.selectedTitleId
      );
    },
  },
  methods: {
    goBack() {
      this.currentStep--;
    },
    onChangePod(event, bool) {
      this.$store.state.calendarSelectedPod = event.target.value;
      this.$store.state.calendarSelectedGearup =
        this.$store.state.scheduler_pods[event.target.value].gearups[0];
    },
    converToArray(obj) {
      const arr = Object.entries(obj).map(([key, value]) => {
        value.key = key;
        return value;
      });
      return arr;
    },
    onChangeReservationType(event) {
      this.reservationType = event;
    },
    gotoNext() {
      if (this.isFormComplete) {
        this.currentStep++;
      }
    },
    getCurrentDate() {
      return {
        dates: [
          // your date
          new Date(Date.now()),
        ],
      };
    },
    convertTime(times) {
      var newtimes = times.map(String);

      var timesToReturn = [];
      newtimes.forEach((time) => {
        var str = time;
        if (time.length == 3) {
          str = "0" + time;
        }
        var newTime = [str.slice(0, 2), ":", str.slice(2)].join("");
        // Check correct time format and split into components
        newTime = newTime
          .toString()
          .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [newTime];

        if (newTime.length > 1) {
          // If newTime format correct
          newTime = newTime.slice(1); // Remove full string match value
          newTime[5] = +newTime[0] < 12 ? "am" : "pm"; // Set AM/PM
          newTime[0] = +newTime[0] % 12 || 12; // Adjust hours
        }
        // return newTime.join (''); // return adjusted newTime or original string
        timesToReturn.push(newTime.join(""));
      });
      return timesToReturn
        .toString()
        .replace(/[\]']+/g, " ")
        .replace(/,/g, ", ");
    },
    onChangeAct(event) {
      this.selectedTitleId = event.target.value;
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async batchApplyTemplate() {
      let vm = this;
      var start = new Date(
        moment(this.batchTemplateStartDate).format("YYYY-MM-DD")
      );
      var end = new Date(
        moment(this.batchTemplateEndDate).format("YYYY-MM-DD")
      );
      var gearupId = this.$store.state.calendarSelectedGearup.gearup_id;
      var capacity = this.$store.state.calendarSelectedGearup.max_users;
      var titleId = "";
      let getStatus = this.$store.state.titles.find(
        (status) => status.shortname === this.selectedTitleId
      );
      if (getStatus != undefined) {
        if (getStatus.shortname == this.selectedTitleId) {
          titleId = getStatus.title_id;
          capacity =
            getStatus.max_player_count >
            this.$store.state.calendarSelectedGearup.max_users
              ? this.$store.state.calendarSelectedGearup.max_users
              : getStatus.max_player_count;
        }
      }
      $("#batchApplyTemplate").modal("hide");

      var promises = [];
      this.$store.state.batchTemplateMultiSelect.forEach((template) => {
        if (template.checked == true) {
          var loop = new Date(start);

          while (loop <= end) {
            var batchTemplateBody = {
              body: {
                site_id: this.$store.state.selected_site_id,
                title_id: titleId,
                schedule_date: `${loop
                  .toISOString()
                  .substr(0, loop.toISOString().indexOf("T"))}`,
                capacity: capacity,
                gearup_id: gearupId,
                reservation_type: this.reservationType,
              },
            };
            promises.push(
              API.post(
                "asulink",
                `/scheduler/schedule/${template.schedule_id}/apply`,
                batchTemplateBody
              )
            );
            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
          }
          this.chosenTemplate(template);
        }
      });

      await Promise.all(promises)
        .then(async () => {
          $("#batch-apply-sucess").modal();
          try {
            await this.$refs.calendar?.checkChange();
            let myData = {
              selected_site_id: this.$store.state.selected_site_id,
              start_time: this.$refs.calendar.lastStart.date,
              end_time: this.$refs.calendar.lastEnd.date,
            };

            this.$store.state.refresh = false;
            await this.$store
              .dispatch("setCalendarSessionsWithStartAndEndDate", myData)
              .then(() => {
                this.$store.state.refresh = true;
              })
              .catch((err) => {
                window.console.log("Error occured", err);
              });
            let tempSelectedPod = this.$store.state.calendarSelectedPod;
            let tempTitles = this.$store.state.titles;
            let SelectedGearup = this.$store.state.calendarSelectedGearup;
            setTimeout(() => {
              vm.$store.state.calendarSelectedPod = tempSelectedPod;
              vm.$store.state.titles = tempTitles;
              vm.$store.state.calendarSelectedGearup = SelectedGearup;
            }, 2500);
          } catch (err) {
            await this.$store.dispatch("setSessions");
          }
        })
        .catch((err) => {
          window.console.log("Error occured", err.response.data);
          this.$toasted.show(`Error: ${err.response.data.message}`, {
            position: "top-right",
            duration: 5000,
          });
        });

      this.emptyModalData();
    },
    emptyModalData() {
      this.currentStep = 1;
      this.batchTemplateStartDate = "";
      this.batchTemplateEndDate = "";
      this.selectedTitleId = "";
      this.$store.state.batchTemplateMultiSelect.forEach((mst, i) => {
        mst.checked = false;
        this.$set(this.$store.state.batchTemplateMultiSelect, i, mst);
      });
    },
    async getSessionBasedOnView() {
      await this.$refs.calendar?.checkChange();

      let myData = {
        selected_site_id: this.$store.state.selected_site_id,
      };
      const { currentDay, currentWeek, currentMonth } = this.getCurrentDates();
      if (this.$store.state.currentSelectedType === "day" && currentDay) {
        myData.start_time = currentDay;
        myData.end_time = currentDay;
      }
      if (this.$store.state.currentSelectedType === "week" && currentWeek) {
        myData.start_time = currentWeek.start;
        myData.end_time = currentWeek.end;
      }
      if (this.$store.state.currentSelectedType === "month" && currentMonth) {
        myData.start_time = currentMonth.start;
        myData.end_time = currentMonth.end;
      }
      await this.$store
        .dispatch("setCalendarSessionsWithStartAndEndDate", myData)
        .then(() => {
          this.$store.state.refresh = true;
          this.isPageRefreshComplete = true;
          this.$store.state.loader = false;
          this.resetFilters();
        })

        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async executeBatchApply() {
      await this.batchApplyTemplate();
      await this.getSessionBasedOnView();
    },
    chosenTemplate(template) {
      this.$store.state.batchTemplateMultiSelect.forEach((mst, i) => {
        if (template.schedule_name == mst.schedule_name) {
          template.checked = !template.checked;

          this.$set(this.$store.state.batchTemplateMultiSelect, i, template);
        }
      });
      this.applyButtonToggle();
    },
    onChangeTemplate(event) {
      this.chosenBatchTemplate = event.target.value;
    },
    applyButtonToggle() {
      var oneTemplateChecked = false;
      this.$store.state.batchTemplateMultiSelect.forEach((mst) => {
        if (
          this.selectedTitleId != "" &&
          this.batchTemplateStartDate != "" &&
          this.batchTemplateEndDate != "" &&
          mst.checked == true
        ) {
          oneTemplateChecked = true;
        }
      });

      this.applyButtonDisabled = oneTemplateChecked;
    },
  },
};
</script>

<style lang="scss" scoped>
.go-back-button-batch-apply {
  color: var(--White, #ecedee);
  font-size: 16px;
  border-radius: 8px;
  background: var(--Grey, #2c2c2c);
  width: 136px;
  height: 42px;
  padding: 10px 37px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.btn:disabled {
  opacity: 0.2 !important;
}
.button-next {
  border: 1px solid #ffc627 !important;
  color: #151515 !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  background-color: #ffc627;
  margin-top: 102px;
  margin-bottom: 32px;
  width: 120px;
  height: 42px;
}
.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container .checkmark {
  width: 16px;
  height: 16px;
  background-color: black;
  border: 1px solid #0e4f74;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
}

.checkbox-container input[type="checkbox"]:checked + .checkmark {
  background-color: #007dc5;
}

.checkbox-container .checkmark::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid BLACK;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

.checkbox-container input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}

.highlighted_url {
  color: #ffc627 !important;
}
.ic-bg {
  background: #3f3f3f;
  padding: 6px 10px;
  border-radius: 50%;
  height: 30px;
}
@media only screen and (max-width: 767px) {
  .ic-bg {
    padding: 2px 4px !important;
    height: 16px !important;
  }
}
#batchApplyTemplate {
  input,
  select {
    border: none;
    background-color: #2d2d2d !important;
  }
  @media only screen and (max-width: 767px) {
    input,
    select {
      max-width: 450px;
      height: 40px !important;
      border: none;
      background-color: #2d2d2d !important;
    }
    .modal-footer {
      border-top: none;
    }
    .apply-button {
      color: #000000 !important;
      background: #ffc627;
    }
    .apply-button,
    .apply-button-disabled {
      border-radius: 6px !important;
      font-size: 16px !important;
      height: 35px !important;
      width: 80% !important;
    }
    .template-v-for {
      max-height: 175px !important;
    }
  }
}
.modal {
  input {
    border: none;
    background-color: #2d2d2d !important;
  }
  .modal-content {
    background-color: #151515;
  }
  .modal-header {
    border-bottom: 0.5px solid #ffc627;
  }
}

.batch-template-modal {
  background-color: #151515;
  color: white;

  .apply-button {
    border: 1px solid #ffc627 !important;
    color: #151515 !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    background-color: #ffc627;
    width: 136px;
    height: 42px;
  }

  .apply-button-disabled {
    color: #151515 !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    background-color: #ffc627;
    opacity: 0.6;
    width: 136px;
    height: 42px;
  }

  .modal-footer {
    border-top: none;
  }
  input:focus {
    background-color: #151515;
  }
  select:focus {
    background-color: #151515;
  }
  select {
    background-color: #151515;
    color: #f2f2f2 !important;
    option {
      color: #f2f2f2;
    }
  }
  .batch-apply-timeSlots {
    display: block;
  }
  .batch-apply-timeSlots-popover {
    display: none;
  }
}

.choose-an-act {
  width: 100%;
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  color: #ffc627;
}

.to-from-dates {
  width: 140px;
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  color: white;
}
.datepicker {
  color: black;
}

.template-v-for {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  border: none;
}

.vdp-datepicker__calendar {
  color: white;
  background-color: #151515 !important;
  font-size: 12px !important;
}

.batch-list {
  border-bottom: 0.5px solid #707070;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: pink;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #4a4a4a;

    .popover-inner {
      background: $color;
      color: white !important;
      padding: 24px;
      border-radius: 0px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

@media only screen and (max-width: 992px) {
  .batch-template-modal {
    .batch-apply-timeSlots {
      display: none;
    }
    .batch-apply-timeSlots-popover {
      display: block;
    }
  }
}
@media only screen and (min-width: 768px) {
  #batchApplyTemplate {
    .modal-content {
      min-width: 522px !important;
    }
  }
}

.input {
  width: 150%;
}
</style>
