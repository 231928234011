import Vue from "vue";
import Vuex from "vuex";
import { API, Auth } from "aws-amplify";
import moment from "moment";
import createPersistedState from "vuex-persistedstate";
import $ from "jquery";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        "envelopeId",
        "agreementId",
        "addedUser",
        "selected_session",
        "selected_avatar_url",
        "selected_avatar_id",
        "choosedAvatarIndex",
        "pod_checkin",
        "selected_site_id",
        "selectedSiteForScheduler",
        "waiverData",
      ],
    }),
  ],
  state: {
    currentSelectedType: "week",
    view: "",
    component: "",
    role: "",
    theme: "",
    podSessionDate: "",
    showCalModal: true,
    refresh: false,
    appointmentsRefresh: true,
    whiteListUsers: [],
    whiteListUserData: [],
    calDates: null,

    //Instructor
    calendarSessionOrders: [],
    batchTemplateMultiSelect: [],
    templates: [],
    sessionsCalendar: [],
    calendarSelectedPod: "",
    calendarSelectedGearup: {
      gearup_id: "ASU00004-03-A",
      max_users: 4,
      pod_id: "ASU00004-03",
      install_date: "2024-02-20",
      gearup_letter: "A",
      status_code: 1100,
      in_use: false,
      status_description: "active",
    },
    calendarEvents: [],

    calendarPodOneEvents: [],
    calendarPodTwoEvents: [],
    calendarPodThreeEvents: [],
    calendarPodFourEvents: [],
    whiteListedUserData: [],
    editTemplateData: {},

    // commom
    profile: {},
    avatars: [],
    sites: [],
    titles: [],
    sessions: [],
    scheduler_pods: [],
    titleid: "alienzoolearnm01r02",
    loader: false,

    // student
    expirenceTIckets: [], // tree
    expierenceAvailabilities: {}, // select pod expierence modal
    selected_title_id: "alienzoolearnm01r02", // selected title_id
    selected_title: "",
    selected_site_id: "ASU00004", // selected site_id
    selected_site_location: "",
    reserved_spot: {}, // selected reserved spot details from pod session
    order_transaction: {}, // response from order POST
    remote_expierence: {}, // remote expierenece
    studentOrders: [], // orders of a signed in
    orderCarousel: true,
    adaSelection: false,
    selectedOrder: {},
    choosedAvatarIndex: 0,
    pod_checkin: Boolean,
    error: {},
    waiverData: {},

    // pod operator
    ticket_details: {}, // ticket details after scan
    selected_session: "", // selected session while adding user
    selected_avatar_id: "alienzoo01-01", // selected Avatar
    selected_avatar_url:
      "https://d11dbhgooo73xe.cloudfront.net/alienzoo01/01/fe-as-01.png",
    checked_in: {}, // user details after check in
    SchedulerSessions: [], // in pod operator get available sessions to get orders wrt them
    orders: [],
    addedUser: {},
    clickedBtn: "", // selected operation on modals
    CancelReservation: null,
    cancelSession: null,

    // SSO
    user: {},
    // profile: {},
    dslApiEnabled: false,

    // Waicer
    docusignEvent: undefined,
    waivers: [],
    envelopeId: undefined,
    agreementId: undefined,

    //On click menu options
    viewFromMenu: "Dashboard",
    allSites: [],
    selectedSiteForScheduler: {},
    allCourses: [],
    selectedCourseForScheduler: {},
  },
  getters: {
    getSelectedDate(state) {
      return state.podSessionDate;
    },
    getSchedulerPods(state) {
      return state.scheduler_pods;
    },

    getUser: (state) => state.user,
    signedIn: (state) => Object.keys(state.user).length !== 0,
    getProfile: (state) => state.profile,
    dslApiEnabled: (state) => state.dslApiEnabled,

    getWaivers: (state) => state.waivers,
    getWaiverEnvelopeId: (state) => state.envelopeId,
    getDocusignEvent: (state) => state.docusignEvent,
  },

  mutations: {
    setCurrentSelectedType(state, selectedType) {
      state.currentSelectedType = selectedType;
    },
    CLEAR_USER_DATA(state) {
      state.whiteListUserData = [];
      state.whiteListUsers = [];
    },
    SET_MYWAIVER(state, data) {
      state.waiverData = data;
    },
    POD_CHECKIN(state, data) {
      state.pod_checkin = data;
    },
    SET_ALLSITES(state, data) {
      state.allSites = data;
      if (Object.keys(state.selectedSiteForScheduler).length === 0) {
        state.selectedSiteForScheduler = data[1];
      }
    },
    SET_SESSIONS(state, data) {
      state.sessionsCalendar = data;
    },
    SET_PROFILE(state, data) {
      state.profile = data;
    },
    SET_GENERAL_SESSIONS(state, data) {
      state.sessions = data;
    },
    SET_AVATARS_GLOBAL(state, data) {
      state.avatars = data;
    },
    SET_AVATARS(state, data) {
      state.avatars = data;
    },
    SET_SITES(state, data) {
      state.sites = data;
    },
    STUDENT_EXPIERENCES(state, data) {
      state.expirenceTIckets = data;
    },
    SET_EXPIERENCE_AVAILABLE(state, data) {
      state.expierenceAvailabilities = data;
    },
    RESET_EXPIERENCE_AVAILABLE(state) {
      state.expierenceAvailabilities = {};
    },
    SET_STUDENT_ORDER(state, data) {
      state.order_transaction = data;
    },
    SET_ORDER_DETAILS(state, data) {
      state.ticket_details = data;
    },
    SET_STUDENT_CHECKIN(state, data) {
      state.checked_in = data;
    },
    SET_WHITELIST_USERS(state, data) {
      data.forEach((element) => {
        state.whiteListUsers.push(element);
      });
    },
    SET_WHITELIST_USERDATA(state, data) {
      data.forEach((element) => {
        state.whiteListUserData.push(element);
      });
    },
    SET_SCHEDULES(state, data) {
      state.templates = data;
    },
    SET_SCHEDULER_SESSIONS(state, data) {
      state.SchedulerSessions = data;
      state.loader = false;
    },
    SET_TITLES(state, data) {
      state.titles = data;
    },
    SET_USER_DETAILS(state, data) {
      state.addedUser = data;
    },
    RESET_USER_DETAILS(state, data) {
      state.addedUser = data;
      state.envelopeId = data;
      state.agreementId = data;
      state.selected_avatar_id = "alienzoo01-01";
      state.selected_avatar_url =
        "https://d11dbhgooo73xe.cloudfront.net/alienzoo01/01/fe-as-01.png";
    },
    SET_STUDENT_ORDERS(state, data) {
      state.studentOrders = data;
      state.orderCarousel = true;
    },
    SET_SESSION_ORDERS(state, data) {
      state.calendarSessionOrders = data;
    },
    SET_POD_ONE_EVENTS(state, data) {
      state.calendarPodOneEvents = data;
    },
    SET_POD_TWO_EVENTS(state, data) {
      state.calendarPodTwoEvents = data;
    },
    SET_POD_THREE_EVENTS(state, data) {
      state.calendarPodThreeEvents = data;
    },
    SET_POD_FOUR_EVENTS(state, data) {
      state.calendarPodFourEvents = data;
    },
    SET_BATCH_TEMPLATES(state, data) {
      state.batchTemplateMultiSelect = data;
    },
    SET_WHITELIST_USER_DATA(state, data) {
      state.whiteListedUserData = data;
    },
    SET_ERROR(state, err) {
      state.error = err;
      state.envelopeId = undefined;
    },

    // SSO

    setProfile(state, payload) {
      state.profile = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setDSLApiFlag(state, flag) {
      state.dslApiEnabled = flag;
    },

    setWaivers(state, payload) {
      state.waivers = payload;
    },
    setEnvelopeId(state, payload) {
      state.envelopeId = payload;
    },
    setAgreementId(state, payload) {
      state.agreementId = payload;
    },
    REFRESH(state) {
      state.refresh = true;
    },
  },
  actions: {
    updateCurrentSelectedType({ commit }, selectedType) {
      commit("setCurrentSelectedType", selectedType);
    },
    async setAllSites({ commit }) {
      return await API.get("proxy", "/common/asu/sites")
        .then((response) => {
          commit("SET_ALLSITES", response);
          return response;
        })
        .catch((err) => {
          return err.response;
        });
    },
    async createTemplate({ commit }, data) {
      return await API.put("asulink", "/scheduler/schedule", data)
        .then((response) => {
          response.status = "200";
          return response;
        })
        .catch((err) => {
          return err.response;
        });
    },
    async editTemplate({ commit }, data, id) {
      return await API.patch("asulink", `/scheduler/schedule/${id}`, data)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async deleteTemplate({ commit, dispatch }, schedule_Id) {
      return await API.del("asulink", `/scheduler/schedule/${schedule_Id}`)
        .then((response) => {
          dispatch("setListOfTemplates");
          response = true;
          return response;
        })
        .catch((err) => {
          return err.response;
        });
    },
    async addASession({ commit, dispatch, state }, details) {
      return await API.put(
        "proxy",
        `/${state.selected_site_id}/dsl/scheduler/session`,
        details.addASessionBody
      )
        .then((response) => {
          dispatch("setCalendarSessionsWithStartAndEndDate", details.myData);
          return response;
        })
        .catch((err) => {
          return err.response;
        });
    },
    async setSessions({ commit, state }) {
      return await API.get(
        "proxy",
        `/${state.selected_site_id}/dsl/scheduler/sessions/${state.selected_site_id}`
      )
        .then((response) => {
          commit("SET_SCHEDULER_SESSIONS", response);
          // pick the skeleton from store/title-mapping
          let skeleton = state.scheduler_pods;
          for (const pod in skeleton) {
            skeleton[pod].scheduler_sessions = [];
          }

          response.forEach((cs) => {
            var conStart = moment(cs.start_timestamp);
            var conEnd = moment(cs.end_timestamp);

            var startDT = conStart.tz("America/Phoenix").format();
            var endDT = conEnd.tz("America/Phoenix").format();
            var startDTFormating = startDT.substr(0, startDT.indexOf("-07:00"));
            var endDTFormating = endDT.substr(0, endDT.indexOf("-07:00"));

            for (const pod in skeleton) {
              if (Object.prototype.hasOwnProperty.call(skeleton, pod)) {
                let currentPod = skeleton[pod];

                if (currentPod.gear_id === cs.gearup_id) {
                  if (!skeleton[pod].scheduler_sessions) {
                    skeleton[pod].scheduler_sessions = [];
                  }
                  if (!Array.isArray(skeleton[pod].scheduler_sessions)) {
                    continue;
                  }
                  // Push the session data to the scheduler_sessions array of the current pod
                  skeleton[pod].scheduler_sessions.push({
                    name: cs.title_name,
                    start: startDTFormating,
                    end: endDTFormating,
                    color: "#151515",
                    sessionData: cs,
                  });
                }
              }
            }
          });

          state.scheduler_pods = skeleton;

          commit("REFRESH");
          return response;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async getCalendarSessionOrders({ commit }, sessionId) {
      return await API.get(
        "asulink",
        `/scheduler/session/${sessionId}/orders`
      ).then((response) => {
        let orders = response;
        commit("SET_SESSION_ORDERS", orders);
      });
    },
    async setListOfTemplates({ commit }) {
      return await API.get("asulink", `/scheduler/schedules`)
        .then((response) => {
          var data = [];
          response.forEach((ts) => {
            var template = ts;
            template["checked"] = false;
            data.push(template);
          });
          commit("SET_BATCH_TEMPLATES", data);
          commit("SET_SCHEDULES", response);
          return response;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setProfile({ commit }) {
      return await API.get("asulink", "/account/profile")
        .then((response) => {
          let profile = response;
          commit("SET_PROFILE", profile);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setGeneralSessions({ commit }) {
      return await API.get("asulink", "/account/sessions")
        .then((response) => {
          commit("SET_GENERAL_SESSIONS", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setAvatarsGlobal({ commit, state }) {
      return await API.get(
        "proxy",
        `/${state.selected_site_id}/dsl/common/avatars`
      )
        .then((response) => {
          commit("SET_AVATARS_GLOBAL", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },

    async setAvatars({ commit }, titleid) {
      return await API.get("asulink", `/common/avatars/${titleid}`)
        .then((response) => {
          commit("SET_AVATARS", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setSites({ commit }) {
      return await API.get("asulink", `/common/sites`)
        .then((response) => {
          commit("SET_SITES", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setStudentExpierences({ commit }, site_id) {
      return await API.get("asulink", `/tickets/experiences/${site_id}`)
        .then((response) => {
          commit("STUDENT_EXPIERENCES", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setExpierenceAvailability({ commit }, payload) {
      return await API.get(
        "asulink",
        `/tickets/experiences/availability/${payload.site_id}/${payload.title_id}/?ada=true&startdate=${payload.start_date}`
      )
        .then((response) => {
          commit("SET_EXPIERENCE_AVAILABLE", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
          commit("RESET_EXPIERENCE_AVAILABLE");
        });
    },

    async setStudentOrder({ commit, state }, order_details) {
      return await API.post(
        "proxy",
        `/${state.selected_site_id}/dsl/tickets/experience/order`,
        order_details
      )
        .then((response) => {
          commit("SET_STUDENT_ORDER", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setTicketTransactions({ commit }, decode_id) {
      return await API.get(
        "asulink",
        `/customer/ticket/id/ASU00001/${decode_id}`
      )
        .then((response) => {
          commit("SET_ORDER_DETAILS", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setCheckin({ commit }, ticket_details) {
      return await API.post(
        "asulink",
        `/customer/checkin/complete`,
        ticket_details
      )
        .then((response) => {
          commit("SET_STUDENT_CHECKIN", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setWhiteListUsers({ commit, state }) {
      return API.get("proxy", `/${state.selected_site_id}/dsl/access/users/`)
        .then((response) => {
          commit("SET_WHITELIST_USER_DATA", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async getWhiteListUserData({ commit }, dataTerms) {
      return await API.get(
        "asulink",
        `/access/sessions/${dataTerms.userId}?include_partial=true&limit=25${
          dataTerms.index > 0 ? "&skip=" + dataTerms.index : ""
        }`
      )
        .then(async (response) => {
          commit("SET_WHITELIST_USERS", response);
          return response;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
          return err;
        });
    },
    async getUserData({ commit }, dataTerms) {
      return await API.get(
        "asulink",
        `/access/userdata/${dataTerms.userId}?include_partial=true&limit=25${
          dataTerms.index > 0 ? "&skip=" + dataTerms.index : ""
        }`
      )
        .then(async (response) => {
          commit("SET_WHITELIST_USERDATA", response);
          return response;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
          return err;
        });
    },

    async setSchedulerSessions({ commit, state }, myData) {
      return await API.get(
        "proxy",
        `/${state.selected_site_id}/dsl/scheduler/sessions/${
          myData.selected_site_id
        }?starttime=${
          myData.startDate ? myData.startDate : myData.podSessionDate
        }T00:00:00-07:00&endtime=${
          myData.end_time ? myData.end_time : myData.podSessionDate
        }T23:59:59-07:00&visibility=all=all&limit=1500`
      )
        .then((response) => {
          response.forEach((cs) => {
            var conStart = moment(cs.start_timestamp);
            var conEnd = moment(cs.end_timestamp);

            var startDT = conStart.tz("America/Phoenix").format();
            var endDT = conEnd.tz("America/Phoenix").format();
            cs.start_timestamp = startDT;
            cs.end_timestamp = endDT;
          });
          commit("SET_SCHEDULER_SESSIONS", response);
          let skeleton = state.scheduler_pods;
          for (const pod in skeleton) {
            skeleton[pod].scheduler_sessions = [];
          }
          response.forEach((cs) => {
            var conStart = moment(cs.start_timestamp);
            var conEnd = moment(cs.end_timestamp);

            var startDT = conStart.tz("America/Phoenix").format();
            var endDT = conEnd.tz("America/Phoenix").format();
            var startDTFormating = startDT.substr(0, startDT.indexOf("-07:00"));
            var endDTFormating = endDT.substr(0, endDT.indexOf("-07:00"));

            for (const pod in skeleton) {
              if (Object.prototype.hasOwnProperty.call(skeleton, pod)) {
                let currentPod = skeleton[pod];
                if (currentPod.gear_id === cs.gearup_id) {
                  if (!skeleton[pod].scheduler_sessions) {
                    skeleton[pod].scheduler_sessions = [];
                  }
                  if (!Array.isArray(skeleton[pod].scheduler_sessions)) {
                    continue;
                  }

                  // Push the session data to the scheduler_sessions array of the current pod
                  if (
                    !skeleton[pod].scheduler_sessions.some(
                      (item) => item.sessionData.session_id === cs.session_id
                    )
                  ) {
                    skeleton[pod].scheduler_sessions.push({
                      name: cs.title_name,
                      start: startDTFormating,
                      end: endDTFormating,
                      color: "#151515",
                      sessionData: cs,
                    });
                  }
                }
              }
            }
          });

          state.scheduler_pods = skeleton;
          return response;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setCalendarSessionsWithStartAndEndDate({ commit, state }, myData) {
      state.refresh = false;
      return await API.get(
        "proxy",
        `/${state.selected_site_id}/dsl/scheduler/sessions/${myData.selected_site_id}?starttime=${myData.start_time}T00:00:00-07:00&endtime=${myData.end_time}T23:59:59-07:00&visibility=all&limit=1500`
      )
        .then((response) => {
          //commit to SchedulerSessions
          commit("SET_SCHEDULER_SESSIONS", response);
          // pick the skeleton from store/title-mapping
          let skeleton = state.scheduler_pods;
          for (const pod in skeleton) {
            skeleton[pod].scheduler_sessions = [];
          }
          response.forEach((cs) => {
            var conStart = moment(cs.start_timestamp);
            var conEnd = moment(cs.end_timestamp);

            var startDT = conStart.tz("America/Phoenix").format();
            var endDT = conEnd.tz("America/Phoenix").format();
            var startDTFormating = startDT.substr(0, startDT.indexOf("-07:00"));
            var endDTFormating = endDT.substr(0, endDT.indexOf("-07:00"));

            for (const pod in skeleton) {
              if (Object.prototype.hasOwnProperty.call(skeleton, pod)) {
                let currentPod = skeleton[pod];
                if (currentPod.gear_id === cs.gearup_id) {
                  if (!skeleton[pod].scheduler_sessions) {
                    skeleton[pod].scheduler_sessions = [];
                  }
                  if (!Array.isArray(skeleton[pod].scheduler_sessions)) {
                    continue;
                  }

                  // Push the session data to the scheduler_sessions array of the current pod
                  if (
                    !skeleton[pod].scheduler_sessions.some(
                      (item) => item.sessionData.session_id === cs.session_id
                    )
                  ) {
                    skeleton[pod].scheduler_sessions.push({
                      name: cs.title_name,
                      start: startDTFormating,
                      end: endDTFormating,
                      color: "#151515",
                      sessionData: cs,
                    });
                  }
                }
              }
            }
          });

          state.scheduler_pods = skeleton;
          commit("REFRESH");
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async setTitles({ commit }) {
      await API.get("asulink", `/common/titles`)
        .then((response) => {
          commit("SET_TITLES", response);
          // return response;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    async sendUserDetails({ commit }, user_details) {
      return await API.post(
        "asulink",
        `/customer/adhoc/checkin/identify/guest`,
        user_details
      )
        .then((response) => {
          commit("SET_USER_DETAILS", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    resetUserDetails({ commit }) {
      commit("RESET_USER_DETAILS", undefined);
    },
    async confirmUserDetails({ commit }, user_details) {
      return await API.post(
        "asulink",
        `/customer/adhoc/checkin/complete`,
        user_details
      )
        .then(async (response) => {
          let tempData = {
            body: {
              first_name: this.state.waiverData.body.first_name,
              last_name: this.state.waiverData.body.last_name,
              email: this.state.waiverData.body.email,
              site_id: this.state.waiverData.body.site_id,
              signing_init_timestamp:
                response.user_session.checked_in_timestamp,
            },
          };

          await API.put(
            "proxy",
            `/${this.state.selected_site_id}/waiver/sign/data`,
            tempData
          );
          $("#checkin-modal").modal();
          commit("RESET_USER_DETAILS", undefined);
          commit("REFRESH");
        })
        .catch((err) => {
          $("#error-checkin").modal();
          commit("RESET_USER_DETAILS", undefined);
          commit("SET_ERROR", err.response.data);
          window.console.log("Error occured", err.response.data.message);
        });
    },
    async resendEmail({ commit, state }, user_details) {
      return await API.post(
        "proxy",
        `/${state.selected_site_id}/dsl/access/user/registration/email`,
        user_details
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          window.console.log(
            "Error occured",
            err.response.data.errors[0].message
          );
          return err.response.data.errors[0].message;
        });
    },
    async deleteCalendarSession({ commit, dispatch, state }, data) {
      return await API.del(
        "proxy",
        `/${state.selectedSiteForScheduler.site_id}/dsl/scheduler/session/${data.session_id}`
      ).catch((err) => {
        window.console.log("Error occured", err);
      });
    },
    async deleteCalendarSessionOrder({ commit, dispatch, state }, data) {
      return await API.del(
        "proxy",
        `/${state.selected_site_id}/dsl/scheduler/session/${data.sessionId}/order/${data.orderId}`
      ).catch((err) => {
        window.console.log("Error occured", err);
      });
    },
    async deleteAllSessionOrders({ commit }, data) {
      return await API.delete(
        "asulink",
        `/scheduler/session/${data.sessionid}/orders`
      ).catch((err) => {
        window.console.log("Error occured", err);
      });
    },
    async deleteCalendarSchedule({ commit }, data) {
      return await API.delete(
        "asulink",
        `/scheduler/schedule/${data.scheduleid}`
      ).catch((err) => {
        window.console.log("Error occured", err);
      });
    },
    async getOutstandingOrders({ commit }) {
      return await API.get("asulink", `/tickets/orders`)
        .then((response) => {
          commit("SET_STUDENT_ORDERS", response);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },

    // SSO
    async loadUser({ commit }) {
      try {
        const response = await Auth.currentAuthenticatedUser();
        commit("setUser", response);
      } catch (error) {
        commit("setUser", {});
      }
    },
    async clearUserState({ commit }) {
      commit("setUser", {});
      commit("setProfile", {});
    },
    async getDslAccountProfile({ commit }) {
      if (this.state.dslApiEnabled) {
        const response = await API.get("asulink", "/account/profile");
        commit("setProfile", response);
      }
    },

    // Waiver
    async resetWaiverState({ dispatch, commit }) {
      await dispatch("loadWaivers");
      commit("setEnvelopeId", undefined);
      commit("setAgreementId", undefined);
      commit("setDocusignEvent", undefined);
    },
    async loadDslAccountProfile({ commit }) {
      if (this.state.dslApiEnabled) {
        const response = await API.get("asulink", "/account/profile");
        commit("setProfile", response);
      }
    },
    async loadWaivers({ commit }) {
      if (this.state.dslApiEnabled) {
        const response = await API.get("asulink", "/account/waivers/valid");
        commit("setWaivers", response);
      }
    },
  },
  modules: {},
});
