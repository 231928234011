<template>
  <div
    class="modal fade px-0"
    id="batchCancelTemplate"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-md br-18 mx-0 mx-md-auto"
      role="document"
    >
      <div
        class="modal-content batch-template-modal animate-bottom br-18"
        style="
          border: 0.5px solid #0b5786 !important;
          box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
        "
      >
        <div
          class="modal-header p-0 d-flex align-items-center"
          style="border-bottom: 0.5px solid #707070 !important; opacity: 0.598"
        >
          <span class="col-2"></span>
          <h5
            class="modal-title font-md-18 font-16 px-0 pb-12p pb-md-20 pt-md-22 pt-14p text-white col-8 text-center kallisto-bold"
          >
            Batch cancel
          </h5>
          <button
            type="button"
            class="close m-0 col-2 pr-20 d-flex justify-content-end"
            data-dismiss="modal"
            @click="emptyModalData()"
          >
            <img
              :src="getIcon('x-button.svg')"
              alt="x-button"
              class="x-button"
            />
          </button>
        </div>
        <div v-if="step1">
          <div class="modal-body px-0 text-left mx-0 pt-0 os-regular">
            <div class="px-6 border-bottom-white mt-4 pb-5">
              <p class="mb-0 pb-10p font-weight-bold font-18 font-md-16">
                Show
              </p>
              <select
                name="LeaveType"
                @change="onChangeAct($event)"
                v-model="this.selectedTitleId"
                placeholder="Select a show"
                class="font-16 font-md-14 px-3 choose-an-act text-white text-left font-weight-bold form-control"
              >
                <option class="text-left" disabled selected value>
                  Select a show
                </option>
                <option
                  class="text-left"
                  v-for="(act, i) in this.$store.state.titles"
                  :key="i"
                >
                  {{ act.shortname }}
                </option>
              </select>
            </div>
            <div>
              <div class="col-12 col-md-12 p-0 m-0">
                <p
                  class="m-0 mt-4 d-block font-18 font-md-16 font-weight-bold pb-10p px-6"
                >
                  Date
                </p>
                <div
                  class="col-12 d-flex justify-content-between border-bottom-white font-16 pt-0 pb-5 px-6"
                >
                  <div>
                    <datepicker
                      v-model="batchTemplateStartDate"
                      :format="customFormatter"
                      :highlighted="getCurrentDate()"
                      class="cursor-pointer date-picker-style-max-length py-3 ml-0 font-16 font-md-14"
                      :disabled-dates="disabledDates"
                      placeholder="Select date"
                    >
                    </datepicker>
                  </div>
                  <div>
                    <p
                      class="font-16 font-md-14 font-weight-bold d-inline-block m-0 pt-3 mt-4"
                    >
                      to
                    </p>
                  </div>
                  <div>
                    <datepicker
                      v-model="batchTemplateEndDate"
                      :format="customFormatter"
                      :highlighted="getCurrentDate()"
                      class="cursor-pointer date-picker-style-max-length py-3 ml-0 font-16 font-md-14"
                      :disabled-dates="disabledDates"
                      placeholder="Select date"
                    >
                    </datepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="col-12 col-md-12 p-0 m-0">
                <p
                  class="m-0 mt-4 d-block font-18 font-md-16 font-weight-bold pb-10p px-6"
                >
                  Time
                </p>
                <div
                  class="col-12 d-flex justify-content-between border-bottom-white font-16 pt-0 pb-5 px-6"
                >
                  <input
                    type="text"
                    v-model="batchTemplateStartTime"
                    class="cursor-pointer p-3 ml-0 font-16 font-md-14"
                    placeholder="HH:MM"
                    v-mask="timeMask"
                  />
                  <p
                    class="font-16 font-md-14 font-weight-bold d-inline-block m-0 pt-3 ml-1 mt-1"
                  >
                    to
                  </p>
                  <input
                    type="text"
                    v-model="batchTemplateEndTime"
                    class="cursor-pointer p-3 ml-2 font-16 font-md-14"
                    placeholder="HH:MM"
                    v-mask="timeMask"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="!batchSessions"
            :disabled="!isStep1Complete"
            type="button"
            class="apply-button mt-160 mb-32"
            @click="getSessions()"
          >
            <span class="font-weight-bold apply">Retrieve sessions</span>
          </button>
        </div>
        <!-- Step2 -->

        <div v-else>
          <div class="py-0 pl-20 pr-20">
            <p class="m-0 font-18 font-md-16 font-weight-bold pb-10p">
              Retrieved sessions
            </p>
          </div>
          <div
            v-if="
              selectedTitleId && batchTemplateStartDate && batchTemplateEndDate
            "
          >
            <div
              v-if="
                batchSessions && getFilteredSessions(batchSessions).length > 0
              "
              class="list-sessions py-2"
            >
              <div
                v-for="(session, ss) in getFilteredSessions(batchSessions)"
                :key="ss"
                class="d-flex justify-content-between p-3 px-0 px-md-3 border-bottom-gold"
              >
                <div class="d-flex align-items-center">
                  <img
                    class="d-none d-md-block"
                    src="../../assets/icons/yellow-multi-select.svg"
                    height="20"
                  />
                  <div class="mx-3">
                    <h2 class="text-capitalize mb-0 font-md-14 font-14 os-bold">
                      {{ session.title_name }}
                    </h2>
                    <p class="mb-0 font-14 font-md-14">
                      {{ session.show_time_description }}
                    </p>
                  </div>
                </div>
                <div style="align-self: center">
                  <p
                    class="mb-0 font-weight-bold font-14 font-md-14"
                    style="color: rgb(146, 146, 146)"
                  >
                    {{ getTime(session.start_timestamp) }} -
                    {{ getTime(session.end_timestamp) }} ({{
                      customFormatter(session.start_timestamp)
                    }})
                  </p>
                </div>
              </div>
            </div>
            <div
              v-else
              class="list-sessions d-flex justify-content-center py-4"
            >
              <p class="font-md-16 font-16 font-weight-bold mx-2">
                No sessions in the selected filters.
              </p>
            </div>
          </div>
          <div
            v-else
            class="list-sessions d-flex justify-content-center py-4"
            style="padding-top: 32px !important"
          >
            <p class="font-16 font-md-16 font-weight-bold mx-5">
              Select act, date, and time range to continue.
            </p>
          </div>
          <div
            v-if="
              batchSessions &&
              validateReservations(getFilteredSessions(batchSessions)).length >
                0
            "
            class="d-flex justify-content-center mt-4"
          >
            <p class="mb-0 text-danger">
              Selected time frame includes reserved seats.
            </p>
          </div>

          <div class="py-3 px-2 d-flex justify-content-center">
            <button
              type="button"
              class="mx-2 my-3"
              :class="[
                batchSessions &&
                getFilteredSessions(batchSessions).length > 0 &&
                validateReservations(getFilteredSessions(batchSessions))
                  .length === 0
                  ? 'apply-button'
                  : 'apply-button-disabled',
              ]"
              :disabled="!batchSessions"
              @click="batchCancelButtonClick"
            >
              <span class="font-weight-bold apply">Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="myModal2" data-backdrop="static">
      <div
        class="modal-dialog modal-dialog-centered modal-md"
        style=""
        v-if="batchSessions"
      >
        <div class="modal-content batch-template-modal">
          <div class="modal-header">
            <h2
              class="modal-title font-weight-bold font-18 kallisto-bold text-left px-4 pt-4"
              style="color: #ffc627"
            >
              Batch Cancel
            </h2>
          </div>
          <div class="container"></div>
          <div class="modal-body">
            <p class="mb-0 p-4 font-16">
              You are about to cancel ({{
                getFilteredSessions(batchSessions).length
              }}) shows, do you wish to continue?
            </p>
          </div>
          <div class="py-3 d-flex justify-content-center my-3 mb-5 pb-5">
            <button
              type="button"
              class="apply-button-white mx-2 mb-1"
              @click="cancelConfirm()"
            >
              <span class="font-weight-bold apply font-md-18 font-16"
                >Cancel</span
              >
            </button>
            <button
              type="button"
              class="mx-2 mb-1"
              :class="
                batchSessions && getFilteredSessions(batchSessions).length > 0
                  ? 'apply-button'
                  : 'apply-button-disabled'
              "
              data-toggle="modal"
              @click="executeBatchCancel(getFilteredSessions(batchSessions))"
              :disabled="!batchSessions"
            >
              <span class="font-weight-bold apply font-md-18 font-16"
                >Confirm</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="batch-cancel-shows-error"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 400px">
        <div
          class="modal-content animate-bottom br-18"
          style="min-width: 400px !important"
        >
          <div class="p-0 modal-body br-18 b-gold">
            <div class="d-flex flex-column text-center">
              <div class="d-flex justify-content-center mt-20">
                <img
                  style="background: #a8252f; border-radius: 50%; padding: 10px"
                  :src="getIcon('x-button.svg')"
                  alt="x-button"
                  class="mt-5"
                  height="32"
                />
              </div>
              <p class="font-14 font-md-18 font-weight-bold mt-3 mb-20">
                Batch cancel unsuccessful. Try again.
              </p>
              <div>
                <button
                  data-dismiss="modal"
                  class="continue-btn os-bold mb-32 px-4"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import $ from "jquery";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      step1: true,
      batchTemplateStartDate: "",
      batchTemplateStartTime: "",
      batchTemplateEndTime: "",
      batchTemplateEndDate: "",
      selectedTitleId: "",
      batchSessions: null,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
        // days: [6, 0], // Disable Saturday's and Sunday's
      },
      //*********************
    };
  },
  props: ["resetFilters"],
  computed: {
    isStep1Complete() {
      return (
        this.selectedTitleId &&
        this.batchTemplateStartDate &&
        this.batchTemplateEndDate &&
        this.batchTemplateStartTime &&
        this.batchTemplateEndTime
      );
    },
  },
  methods: {
    gotoNext() {
      this.step1 = false;
    },
    getCurrentDate() {
      return {
        dates: [
          // your date
          new Date(Date.now()),
        ],
      };
    },
    batchCancelButtonClick(event) {
      if (this.getFilteredSessions(this.batchSessions).length > 0) {
        $("#myModal2").modal("show");
      } else {
        event.preventDefault();
      }
    },
    resetFiltersMethod() {
      this.$emit("handleRefresh");
    },
    validateReservations(sessions) {
      return sessions.filter((e) => e.seats_reserved > 0);
    },
    getFilteredSessions(sessions) {
      return sessions.filter((e) => e.title_name == this.selectedTitleId);
    },
    getTime(time) {
      return moment(time).format("hh:mm A");
    },
    cancelConfirm() {
      $("#myModal2").modal("hide");
    },
    async getSessions() {
      try {
        this.step1 = false;
        let start = this.batchTemplateStartTime.split(":");
        let end = this.batchTemplateEndTime.split(":");
        let myData = {
          selected_site_id: this.$store.state.selected_site_id,
          start_time: this.batchTemplateStartDate,
          end_time: this.batchTemplateEndDate,
        };
        await API.get(
          "asulink",
          `/scheduler/sessions/${
            myData.selected_site_id
          }?starttime=${myData.start_time
            .toISOString()
            .substr(0, myData.start_time.toISOString().indexOf("T"))}T${
            start[0]
          }:${start[1]}:00-07:00&endtime=${myData.end_time
            .toISOString()
            .substr(0, myData.end_time.toISOString().indexOf("T"))}T${end[0]}:${
            end[1]
          }:59-07:00&visibility=all&limit=1500`
        ).then((response) => {
          this.batchSessions = response;
        });
      } catch (err) {
        $("#batch-cancel-shows-error").modal();
        console.error("Error getting batch sessions", err);
      }
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    onChangeAct(event) {
      this.selectedTitleId = event.target.value;
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async batchApplyTemplate(sessions) {
      const vm = this;
      const promises = sessions.map((template) =>
        API.del(
          "proxy",
          `/${this.$store.state.selectedSiteForScheduler.site_id}/dsl/scheduler/session/${template.session_id}`
        )
      );

      try {
        await Promise.all(promises);
        $("#myModal2").modal("hide");
        $("#cancel-shows-success").modal("hide");
        $("#batchCancelTemplate").modal("hide");

        const myData = {
          selected_site_id: this.$store.state.selected_site_id,
          start_time: this.$store.state.calDates.lastStart.date,
          end_time: this.$store.state.calDates.lastEnd.date,
        };
        await this.$store.dispatch(
          "setCalendarSessionsWithStartAndEndDate",
          myData
        );
        vm.resetFilters();
        this.$store.state.refresh = false;
        const { calendarSelectedPod, titles, calendarSelectedGearup } =
          this.$store.state;

        setTimeout(() => {
          vm.$store.state.calendarSelectedPod = calendarSelectedPod;
          vm.$store.state.titles = titles;
          vm.$store.state.calendarSelectedGearup = calendarSelectedGearup;
        }, 2500);
      } catch (e) {
        console.error("Error occurred:", e);
        this.$toasted.show(
          `Error: ${e.message || "An unknown error occurred"}`,
          {
            position: "top-right",
            duration: 5000,
          }
        );

        await this.$store.dispatch("setSessions");
      }

      this.emptyModalData();
      this.$store.state.refresh = true;
    },
    emptyModalData() {
      this.step1 = true;
      this.batchSessions = null;
      this.batchTemplateStartDate = "";
      this.batchTemplateEndDate = "";
      this.selectedTitleId = "";
      this.batchTemplateEndTime = "23:59";
    },
    async executeBatchCancel(sessions) {
      await this.batchApplyTemplate(sessions);
    },
  },
};
</script>

<style lang="scss">
.vdp-datepicker {
  input {
    width: 200px !important;
    height: 53px !important;
    border-radius: 5px;
    color: white;
    text-align: center;
    cursor: pointer;
    border: none !important;
    background-color: #2d2d2d !important;
  }
}
.modal {
  input {
    background-color: #151515;

    max-height: 4rem;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    color: #fff;
    text-align: center;
  }
  .modal-content {
    background-color: #151515;
  }
  .modal-header {
    border-bottom: none !important;
  }
}
#batchCancelTemplate {
  input,
  select {
    border: none !important;
    background-color: #2d2d2d !important;
  }
  .apply-button,
  .apply-button-white,
  .apply-button-disabled {
    border-radius: 8px !important;
  }
  @media only screen and (max-width: 767px) {
    .list-sessions {
      min-height: 160px !important;
    }
    input {
      max-width: 450px;
      height: 32px !important;
      border: none !important;
      background-color: #2d2d2d !important;
    }
    .modal-footer {
      border-top: none;
    }
    .apply-button {
      color: #000000 !important;
      background: #ffc627;
    }
    .apply-button,
    .apply-button-white,
    .apply-button-disabled {
      border-radius: 8px !important;
      font-size: 16px !important;
      width: 180px;
      height: 42px;
    }
    select {
      background-color: #2d2d2d !important;
      color: #f2f2f2 !important;
      border: none !important;
      option {
        color: #f2f2f2 !important;
      }
    }
  }

  .modal-dialog {
    border-radius: 12px;
  }
  .show {
    background: #15151585;
  }
  .modal {
    input {
      background-color: #151515;
      max-width: 8.5rem;
      max-height: 4rem;
      border: 1px solid #f2f2f2;
      border-radius: 5px;
      color: #fff;
      text-align: center;
    }
    .modal-content {
      background-color: #151515;
      border-radius: 12px;
    }
    .modal-header {
      border-bottom: 0.5px solid #ffc627;
    }
  }

  .batch-template-modal {
    background-color: #151515;
    color: white;

    .apply-button {
      border: 1px solid #ffc627 !important;
      color: #151515 !important;
      font-size: 16px !important;
      border-radius: 8px !important;
      background-color: #ffc627;
      width: 180px;
      height: 42px;
    }
    .apply-button:hover {
      color: #151515 !important;
      background-color: #ffc627 !important;
    }
    .apply-button-white {
      border: 1px solid white !important;
      color: white !important;
      font-size: 16px !important;
      border-radius: 8px;
      background-color: #151515;
      width: 180px;
      height: 42px;
    }
    .apply-button-white:hover {
      color: #151515 !important;
      background-color: white !important;
    }

    .apply-button-disabled {
      border: 1px solid #707070 !important;
      color: #707070 !important;
      font-size: 16px !important;
      border-radius: 8px;
      background-color: #151515;
      width: 180px;
      height: 42px;
    }

    .modal-footer {
      border-top: 1px solid #ffc627;
    }
    input:focus {
      background-color: #151515;
    }
    select:focus {
      background-color: #151515;
    }
    select {
      background-color: #151515;
      color: #ffc627;
      option {
        color: #ffc627;
      }
    }
    .batch-apply-timeSlots {
      display: block;
    }
    .batch-apply-timeSlots-popover {
      display: none;
    }
  }
  .select-icon {
    position: absolute;
    right: 6%;
    top: 40%;
    transform: rotate(270deg);
  }
  .choose-an-act {
    height: 47px;
    border: 1px solid #ffc627;
    border-radius: 5px;
    color: #f2f2f2;
  }

  .to-from-dates {
    width: 140px;
    height: 53px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    margin-left: 16px;
    color: white;
  }

  .datepicker {
    color: black;
  }

  .template-v-for {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
    border-top: 0.5px solid #707070;
    border-bottom: 0.5px solid #707070;
  }

  .vdp-datepicker {
    input {
      width: 200px !important;
      height: 53px !important;
      border-radius: 5px;
      color: white;
      text-align: center;
      cursor: pointer;
      border: none !important;
      background-color: #2d2d2d !important;
    }
    .prev {
      background-color: grey !important;
      left: 12%;
    }

    .next {
      background-color: grey !important;
      right: 12%;
    }
  }

  .vdp-datepicker__calendar {
    color: white;
    background-color: #151515 !important;
    font-size: 12px !important;
  }
  .batch-list {
    border-bottom: 0.5px solid #707070;
  }

  .tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      color: white;
      border-radius: 16px;
      padding: 5px 10px 4px;
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: pink;
      z-index: 1;
    }

    &[x-placement^="top"] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="bottom"] {
      margin-top: 5px;

      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="right"] {
      margin-left: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^="left"] {
      margin-right: 5px;

      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #4a4a4a;

      .popover-inner {
        background: $color;
        color: white !important;
        padding: 24px;
        border-radius: 0px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden="true"] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }

  @media only screen and (max-width: 992px) {
    .batch-template-modal {
      .batch-apply-timeSlots {
        display: none;
      }
      .batch-apply-timeSlots-popover {
        display: block;
      }
    }
  }
  .list-sessions {
    background: #252525;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
    min-height: 300px;
  }
}
@media only screen and (min-width: 768px) {
  #batchCancelTemplate {
    .modal-content {
      min-width: 522px !important;
    }
  }
}
</style>
